/* ----- TYPOGRAPHY ---------------- */
/**
* Adieu
* -----
* font-family: Adieu, sans-serif;
*
*/
@font-face {
    font-family: 'Adieu';
    src: url($typography__adieu-regular--woff2) format('woff2'),
         url($typography__adieu-regular--woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

/*
 @font-face {
    font-family: 'Satoshi';
    src: url($typography__adieu-regular--woff2) format('woff2'),
         url($typography__adieu-regular--woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
 */
@font-face {
    font-family: 'Neue Haas Unica';
    src: url($typography__neueHaasUnica-regular--woff2) format('woff2'),
         url($typography__neueHaasUnica-regular--woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neue Haas Unica';
    src: url($typography__neueHaasUnica-black--woff2) format('woff2'),
         url($typography__neueHaasUnica-black--woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

/**
* Noto Sans
* ---------
* font-family: 'Noto Sans', sans-serif;
*
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import url('https://fonts.cdnfonts.com/css/satoshi');

/**
* Rubik
* -----
* font-family: 'Rubik', sans-serif;
*
*/
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap');